import { EmotionStyles } from "../../interfaces/emotion";
import { breakpointMixins } from "../../styles/breakpoints";
import { variables } from "../../styles/variables";

export const styles: EmotionStyles = {
  container: {
    paddingTop: variables.spacing.medium,
    [breakpointMixins.lg]: {
      paddingTop: variables.spacing.large
    }
  }
};
