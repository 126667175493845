import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList, {
  GraphQLError
} from "../components/graphql-error-list";
import SEO from "../components/SEO";
import { SiteInterface } from "../pages";
import { ProductBrandInterface, ProductInterface } from "../interfaces/product";
import { NodesInterface } from "../interfaces/blogPost";
import Brand from "../components/Brand";
import { ProductFilterContextProvider } from "../contexts/ProductFilterContext";

interface BrandTemplateProps {
  data: {
    brand: ProductBrandInterface;
    products: NodesInterface<ProductInterface>;
    site: SiteInterface;
  };
  errors: Array<GraphQLError>;
}

export const query = graphql`
  query BrandTemplateQuery($id: String!) {
    brand: sanityProductBrand(id: { eq: $id }) {
      id
      title
      description
    }
    products: allSanityProduct {
      nodes {
        ...ProductFragment
      }
    }
    site: sanitySiteSettings {
      title
      keywords
      description
    }
  }
`;

const ProductTemplate: React.FC<BrandTemplateProps> = props => {
  const { data, errors } = props;
  const brand = data && data.brand;
  const site = data && data.site;
  const products = data && data.products.nodes;
  return (
    <>
      {errors && <SEO title="GraphQL Error" />}
      {brand && site && (
        <SEO
          title={`${brand.title || "Untitled"} | ${site.title}`}
          description={brand.description ?? site.description}
          keywords={site.keywords}
        />
      )}

      {errors && <GraphQLErrorList errors={errors} />}
      {brand && (
        <ProductFilterContextProvider
          brands={[brand]}
          products={products.filter(product => product.brand.id === brand.id)}
          categories={[]}
          enabledFilters={["brands"]}
        >
          <Brand brand={brand} />
        </ProductFilterContextProvider>
      )}
    </>
  );
};

export default ProductTemplate;
