import React from "react";
import { ProductBrandInterface } from "../../interfaces/product";
import { styles } from "./styles";
import ProductPreviewList from "../ProductPreviewList";
import Button from "../Button";
import { Link } from "gatsby";
import Inner from "../Inner";

interface BrandProps {
  brand: ProductBrandInterface;
}

const Brand: React.FC<BrandProps> = ({ brand }) => {
  return (
    <div css={styles.container}>
      <ProductPreviewList
        title={`Products from ${brand.title}`}
        enableFilters={false}
        content={brand.description ?? undefined}
        headingLevel="h1"
      />
      <Inner>
        <Button asWrapper={true} type="secondary">
          <Link to="/shop">See all products in The Shop</Link>
        </Button>
      </Inner>
    </div>
  );
};

export default Brand;
